import React from 'react';
import logo from './php-camp-logo-04.svg';

function Header() {
  return (
    <header className="bg-white shadow-md py-4 sm:py-6">
      <div className="container mx-auto flex justify-center px-4 sm:px-6">
        <img src={logo} className="h-20 sm:h-24" alt="logo" />
      </div>

    </header>
  );
}

export default Header;
